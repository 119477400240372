import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../utils/normalize.css'
import '../utils/css/screen.css'

class SubmitPostPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Submit posting" />
        <Helmet
          meta={[
            {
              name: `viewport`,
              content: 'width=device-width, initial-scale=1, maximum-scale=1',
            },
          ]}
        />
        <div style={{ height: '75vh' }}>
          <iframe
            title="airtable-embed"
            className="airtable-embed"
            src="https://airtable.com/embed/shrNBa8YvpHpUfO63?backgroundColor=green"
            frameBorder="0"
            onmousewheel=""
            width="100%"
            height="100%"
            style={{ background: 'transparent', border: '1px solid #ccc' }}
          ></iframe>
        </div>
      </Layout>
    )
  }
}

export default SubmitPostPage
